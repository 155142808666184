export const colors = {
  black: "#131618",
  white: "#ffffff",
  transparent: "transparent",
  current: "ffffff",
  whiteAlpha: {
    "50": "rgba(255, 255, 255, .06)",
    "100": "rgba(255, 255, 255, .08)",
    "200": "rgba(255, 255, 255, .12)",
    "300": "rgba(255, 255, 255, .20)",
    "400": "rgba(255, 255, 255, .28)",
    "500": "rgba(255, 255, 255, .40)",
    "600": "rgba(255, 255, 255, .52)",
    "700": "rgba(255, 255, 255, .68)",
    "800": "rgba(255, 255, 255, .88)",
    "900": "rgba(255, 255, 255, .92)",
  },
  blackAlpha: {
    "50": "rgba(26, 29, 33, .04)",
    "100": "rgba(26, 29, 33, .06)",
    "200": "rgba(26, 29, 33, .08)",
    "300": "rgba(26, 29, 33, .16)",
    "400": "rgba(26, 29, 33, .24)",
    "500": "rgba(26, 29, 33, .36)",
    "600": "rgba(26, 29, 33, .48)",
    "700": "rgba(26, 29, 33, .60)",
    "800": "rgba(26, 29, 33, .72)",
    "900": "rgba(26, 29, 33, .88)",
  },
  pink: {
    "50": "#FFEBF7",
    "100": "#FFDBF0",
    "200": "#FAB2D5",
    "300": "#F877B5",
    "400": "#E8549E",
    "500": "#D11776",
    "600": "#AA1361",
    "700": "#810E56",
    "800": "#5C1045",
    "900": "#450C34",
  },
  purple: {
    "50": "#F3ECFD",
    "100": "#E0D8FD",
    "200": "#CCBAF3",
    "300": "#B4A1F2",
    "400": "#987DF2",
    "500": "#6C4EE4",
    "600": "#4D38B2",
    "700": "#361B82",
    "800": "#251F5C",
    "900": "#1E1943",
  },
  cyan: {
    "50": "#D5F6FF",
    "100": "#B8EBFF",
    "200": "#80DBFF",
    "300": "#00B4F1",
    "400": "#009AD6",
    "500": "#0880AF",
    "600": "#005B80",
    "700": "#184F6D",
    "800": "#004054",
    "900": "#032B3A",
  },
  blue: {
    "50": "#ECF5FE",
    "100": "#CDE5FE",
    "200": "#8FC6FA",
    "300": "#5CADFF",
    "400": "#298AF9",
    "500": "#006BE5",
    "600": "#18519F",
    "700": "#073C78",
    "800": "#0E305C",
    "900": "#00213D",
  },
  teal: {
    "50": "#DFF7F6",
    "100": "#C4F2F1",
    "200": "#93EBE7",
    "300": "#33D1C3",
    "400": "#0D9C94",
    "500": "#0D7977",
    "600": "#005C5C",
    "700": "#09504F",
    "800": "#0B3B42",
    "900": "#082A30",
  },
  orange: {
    "50": "#FFECDE",
    "100": "#FFD1AD",
    "200": "#FCB986",
    "300": "#FC955A",
    "400": "#F26236",
    "500": "#EF4923",
    "600": "#C23710",
    "700": "#852710",
    "800": "#611405",
    "900": "#3B0802",
  },
  red: {
    "50": "#FEECED",
    "100": "#FFD3CF",
    "200": "#FF9F96",
    "300": "#F86A6A",
    "400": "#F24052",
    "500": "#D81838",
    "600": "#A31526",
    "700": "#73001F",
    "800": "#590019",
    "900": "#360202",
  },
  green: {
    "50": "#E1F9EE",
    "100": "#C2F5DC",
    "200": "#9BE8C5",
    "300": "#5FD393",
    "400": "#109E61",
    "500": "#028D4A",
    "600": "#01733E",
    "700": "#01592C",
    "800": "#023B1F",
    "900": "#072B15",
  },
  gray: {
    "50": "#F5F8F9",
    "100": "#ECF1F4",
    "200": "#DFE6EC",
    "300": "#CCD3DB",
    "400": "#88929E",
    "500": "#6A737D",
    "600": "#464D55",
    "700": "#30363C",
    "800": "#25292E",
    "900": "#1A1D21",
  },
  yellow: {
    "50": "#FFF1DB",
    "100": "#FFE5B8",
    "200": "#FED480",
    "300": "#FFC94C",
    "400": "#FDB732",
    "500": "#D17600",
    "600": "#A14C02",
    "700": "#703A07",
    "800": "#4E2B13",
    "900": "#2E2405",
  },
};

import {
	FileTextIcon,
	TokensIcon,
	BarChartIcon,
	ChatBubbleIcon,
	LayersIcon,
	TransformIcon,
	CubeIcon,
	StackIcon,
	ColorWheelIcon,
	InputIcon,
	GlobeIcon,
	SpeakerLoudIcon,
	PieChartIcon,
} from "@radix-ui/react-icons";

export const projects = [
	{
		id: 1,
		title: "Unified Design Language",
		nickname: "Unified Design Language",
		icon: TokensIcon,
		logo: "/assets/logos/udl-icon.svg",
		date: "2023",
		path: "/unified-design-language",
		description: "Consolidating multiple design systems into a single source of truth",
		company: "EVERFI",
		imageUrl: "",
		liveUrl: "",
		codeUrl: "",
		bg: "bg-udl",
		fg: "emphasis",
		colorScheme: "blackAlpha",
		pattern: "",
		tags: ["Design System"],
	},
	{
		id: 2,
		title: "Furnace Design System",
		nickname: "Furnace Design System",
		icon: ColorWheelIcon,
		date: "2019 – present",
		logo: "/assets/logos/furnace-icon.png",
		path: "/furnace",
		description: "A theming-driven design system for e-learning products",
		company: "EVERFI",
		imageUrl: "",
		liveUrl: "",
		codeUrl: "",
		bg: "bg-furnace",
		fg: "inverted",
		colorScheme: "blackAlpha",
		pattern: "pattern.svg",
	},
	{
		id: 3,
		title: "Forge Editor",
		nickname: "Forge Editor",
		icon: CubeIcon,
		date: "2022",
		logo: "/assets/logos/forge-icon.png",
		path: "/forge",
		description: "Foundational refresh of EVERFI's course builder app",
		company: "EVERFI",
		imageUrl: "",
		liveUrl: "",
		codeUrl: "",
		bg: "bg-forge",
		fg: "inverted",
		colorScheme: "gray",
	},

	{
		id: 4,
		title: "Conversational Immigration Forms",
		nickname: "Conversational UI",
		icon: InputIcon,
		path: "/conversational-immigration-forms",
		date: "2017",
		logo: "/assets/logos/ellis-icon.svg",
		description: "Improving government paperwork with conversation",
		company: "MICA",
		imageUrl: "",
		liveUrl: "",
		codeUrl: "",
		bg: "bg-mica",
		fg: "",
		colorScheme: "gray",
	},
	{
		id: 5,
		path: "/npr-maps",
		title: "Mapping and Population System",
		logo: "/assets/logos/npr-icon.svg",
		nickname: "NPR Labs",
		icon: GlobeIcon,
		date: "2017",
		description: "Rebuilding NPR's station coverage mapping tool",
		company: "NPR Labs",
		imageUrl: "",
		liveUrl: "",
		codeUrl: "",
		bg: "bg-npr",
		fg: "",
		colorScheme: "teal",
	},
	// {
	//   // id: 6,
	//   path: "/everfi-data-science",
	//   title: "Data Science Labs",
	//   hide: true,
	//   nickname: "Data Science",
	//   icon: PieChartIcon,
	//   date: "2017 – 2022",
	//   description: "Select projects",
	//   company: "EVERFI",
	//   imageUrl: "",
	//   liveUrl: "",
	//   codeUrl: "",
	//   bg: "",
	//   fg: "",
	//   colorScheme: "orange",
	// },
	// {
	//   // id: 7,
	//   path: "/audio-design",
	//   title: "Designing an Accessible Audio Player",
	//   nickname: "Accessible Audio",
	//   icon: SpeakerLoudIcon,
	//   hide: true,
	//   date: "2021",
	//   description: "When voiceover meets VoiceOver",
	//   company: "EVERFI",
	//   imageUrl: "",
	//   liveUrl: "",
	//   codeUrl: "",
	//   bg: "",
	//   fg: "",
	//   colorScheme: "orange",
	// },
	// {
	//   id: 8,
	//   hide: true,
	//   path: "/everfi-engage",
	//   title: "Engage",
	//   nickname: "Engage",
	//   icon: SpeakerLoudIcon,
	//   date: "2017",
	//   description: "Lorem",
	//   company: "EVERFI",
	//   imageUrl: "",
	//   liveUrl: "",
	//   codeUrl: "",
	//   bg: "",
	//   fg: "",
	//   colorScheme: "orange",
	// },
	// Add more projects here
];

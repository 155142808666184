export default {
	p1: {
		fontSize: "md",
		lineHeight: "",
	},

	xs: {
		fontSize: "xs",
		lineHeight: "1.125rem", // 18px
	},
	sm: {
		fontSize: "sm",
		lineHeight: "1.25rem", // 20px
	},
	md: {
		fontSize: "md",
		lineHeight: "1.5rem", // 24px
	},
	lg: {
		fontSize: "lg",
		lineHeight: "1.75rem", // 28px
	},
	xl: {
		fontSize: "xl",
		lineHeight: "1.875rem", // 30px
	},
	"2xl": {
		fontSize: "2xl",
		lineHeight: "2rem", // 32px
	},
	"3xl": {
		fontSize: "3xl",
		lineHeight: "2.375rem", // 38px
	},
	"4xl": {
		fontSize: "4xl",
		lineHeight: "2.75rem", // 44px
		letterSpacing: "-0.02em",
	},
	"5xl": {
		fontSize: "5xl",
		lineHeight: "3.75rem", // 60px
		letterSpacing: "-0.02em",
	},
	"6xl": {
		fontSize: "6xl",
		lineHeight: "4.5rem", // 72px
		letterSpacing: "-0.02em",
	},
	"7xl": {
		fontSize: "7xl",
		lineHeight: "5.75rem", // 92px
		letterSpacing: "-0.02em",
	},
	caption: {
		fontWeight: "regular",
		mx: "auto",
		fontSize: "xs",
		letterSpacing: "wide",
		color: "text-subdued",
	},
	divider: {
		fontSize: "xs",
		fontFamily: "heading",
		color: "text-subdued",
		fontWeight: "bold",
		textTransform: "uppercase",
		lineHeight: "1rem",
		letterSpacing: "widest",
	},
	"title-sm": {
		fontSize: "2xs",
		textTransform: "uppercase",
		lineHeight: "taller",
		fontWeight: "bold",
		fontFamily: "heading",
		color: "text-subdued",
	},
	subtitle: {
		fontFamily: "heading",
		fontWeight: "light",
		color: "text-subdued",
		fontSize: "3xl",
		lineHeight: "shorter",
		letterSpacing: "tightest",
	},
};

export const pageData = {
	index: {
		title: "",
		description: "About",
		imageUrl: "",
	},
	about: {
		title: "About Page",
		description: "Info about me!",
		imageUrl: "",
	},
	"unified-design-language": {
		title: "Unified Design Language",
		imageUrl: "/assets/logos/udl-icon.svg",
		subtitle: "Consolidating EVERFI's platform and product design systems with a shared design language",
		role: "Design Systems Lead",
		path: "/unified-design-language",
		team: [
			{
				name: "Marianne Epstein",
				role: "Director, Platform UX",
				url: "https://www.marianneepstein.com/",
			},
			{
				name: "Alyssa Kjar",
				role: "Sr. UX Designer",
				url: "https://www.linkedin.com/in/alyssakjar/",
			},
			{
				name: "Jey Nandakumar",
				role: "Engineering Lead",
				url: "",
			},
		],
		date: "2021 – present",
	},
	forge: {
		title: "Forge",
		subtitle: "Building (and re-building) the builder",
		role: "Design Systems Lead",
		team: [
			{
				name: "Kevin Grolton-Francisco",
				role: "Product Manager",
			},
			{
				name: "Annie Alvarado",
				role: "User Research",
				url: "http://anniealvarado.com/",
			},
		],
		date: "Winter - Spring 2017",
	},
	thesis: {
		title: "Immigration Forms as Conversational Interfaces",
		subtitle: "Thesis Project, MICA",
		role: "Graduate Student",
		team: [
			{
				name: "Glennette Clark",
				role: "Advisor",
				url: "https://www.mica.edu/graduate-programs/design-leadership-ma-mba/glennette-clark-2/",
			},
		],
		date: "Fall 2017",
	},
	"npr-maps": {
		title: "NPR Labs",
		description: "Designing responsive and performant web app for NPR affiliate stations",
		subtitle: "Mapping & Population System",
		role: "UI/UX Designer",
		team: [
			{
				name: "Christian Wood",
				role: "Developer",
				url: "https://christianwood.net/",
			},
			{
				name: "Alice Goldfarb",
				role: "Director, NPR Labs",
				url: "http://www.alicegoldfarb.com/",
			},
		],
		date: "Winter-Spring 2017",
	},
	"conversational-immigration-forms": {
		title: "Conversational Immigration Forms",
		subtitle: "Thesis Project, Masters in UX",
		role: "Graduate Student",
	},
	engage: {
		title: "Engage Financial Workshops",
		subtitle: "Designing for an LMI audience in a hybrid setting",
		role: "UX Designer",
		team: [
			{
				name: "Frank Stewart",
				role: "UX Design",
				url: "https://frankfrankfrankfrank.github.io/",
			},
			{
				name: "Ann Schoew",
				role: "Illustration",
				url: "http://annschoew.com/",
			},
			{
				name: "Anisha Sridhar",
				role: "Content",
				url: "https://www.linkedin.com/in/anishasridhar/",
			},
		],
		date: "Fall 2017",
	},
	achieve: {
		title: "Achieve Financial Education",
		description: "lorem",
		subtitle: "lorem",
		role: "UX Lead",
		team: [
			{
				name: "Alex Isen",
				role: "Learning Experience",
				url: "https://www.linkedin.com/in/alexandraisen",
			},
			{
				name: "Lundon Jackson",
				role: "Learning Experience",
				url: "https://www.linkedin.com/in/drlundonjackson",
			},
			{
				name: "Terry Biddle",
				role: "Product Art Director",
				url: "https://www.terrybiddle.com/",
			},
		],
		date: "Fall 2017",
	},
	"data-science": {
		title: "Data Science Labs",
		description: "lorem",
		subtitle: "lorem",
		role: "UX Lead",
		team: [
			{
				name: "Ed Guillen",
				role: "UX Design",
			},
			{
				name: "Ann Schoew",
				role: "Art Direction",
			},
			{
				name: "Jason Reyna",
				role: "Animation",
			},
			{
				name: "Elizabeth Heiter",
				role: "Content",
			},
			{
				name: "Jacob Broderick",
				role: "Content",
			},
			{
				name: "Joel Fiser",
				role: "Engineering",
			},
			{
				name: "Eddielynn Macasa",
				role: "Implementation",
			},
		],
		date: "2020 – 2021",
	},
	furnace: {
		title: "Furnace Design System",
		subtitle: "An accessible, themeable, and scalable design system for e-learning products",
		description: "lorem",
		role: "Lead Designer",
		team: [
			{
				name: "Kevin Grolton-Francisco",
				role: "Product Manager",
			},
			{
				name: "Sadeep Nonis",
				role: "Project Manager",
				// url: "https://www.linkedin.com/in/sadeepnonis/details/experience/",
			},
			{
				name: "Shannon Urban",
				role: "Director, Accessibility",
			},
			{
				name: "Carlos Henriquez",
				role: "Dev Lead",
			},
			{
				name: "Mpho Ramataboee",
				role: "Engineering Lead",
				// url: "https://www.linkedin.com/in/mpho-ramataboee",
			},
			{
				role: "+ many others",
			},
		],
		date: "2019 – 2022",
	},
};

export default {
  layerStyles: {
    phone: {
      border: "4px",
      borderImage: "linear-gradient(to bottom, red, rgba(0, 0, 0, 0)) 1 100%",
      p: "20",
      color: "red.500",
    },
    selected: {
      bg: "teal.500",
      color: "teal.700",
      borderColor: "orange.500",
    },
  },
};

// borderWidth: 3px;
// border-style: solid;
// border-image:
